<template>
    <div>
        <card-table-search title="Utilisateurs en attente de validation"
                           subtitle="Membres du Cluji ou d'une association extérieure pas encore validés"
                           :fields="fieldsAsso"
                           :actions="actionsNewUsers"
                           :items="newUsers"
                           :starts-open="newUsers.length > 0"/>
        <card-table-search title="Membres du Cluji"
                           subtitle="Membres validés par le bureau, inscrits au Cluji pour cette année"
                           :fields="fieldsRoles"
                           :actions="actions"
                           :items="clujiUsers"
                           :starts-open="false"/>
        <card-table-search title="Membres d'une association extérieure"
                           subtitle="Membres validés par le bureau appartenant à une autre association que le Cluji"
                           :fields="fieldsAsso"
                           :actions="actions"
                           :items="assoUsers"
                           :starts-open="false"/>
        <card-table-search title="Utilisateurs lambda"
                           subtitle="Utilisateurs sans association particulière"
                           :fields="fieldsLambda"
                           :actions="actions"
                           :items="lambdaUsers"
                           :starts-open="false"/>
    </div>
</template>

<script>
    const AdminUserValidate  = () => import('@/components/modals/AdminUserValidate');
    const CardTableSearch    = () => import("@/components/CardTableSearch");
    const AdminEditUserModal = () => import("@/components/modals/AdminEditUserModal");

    import http                        from "@/util/http";
    import alert                       from "@/util/alert";
    import {formatDate}                from '@/util/date'
    import {formatRole}                from "@/util/role";
    import {momentDates, textUsername} from '@/util/user';

    export default {
        name: "AdminUtilisateurs",
        components: {CardTableSearch},
        data() {
            return {
                fieldActions: {key: 'actions', label: 'Actions'},
                oldClujistesWantRevalidation: [],
                clujiUsers: [],
                assoUsers: [],
                lambdaUsers: [],
                actions: [
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editUserModal(item)
                    }
                ],
                fields: [
                    {
                        key: 'prenom',
                        label: 'Nom',
                        sortable: true,
                        formatter: (value, key, item) => textUsername(item)
                    },
                    {
                        key: 'email',
                        label: 'Email',
                        sortable: true
                    },
                    {
                        key: 'dateInscription',
                        label: "Date d'inscription",
                        sortable: true,
                        formatter: value => formatDate(value)
                    }
                ]
            };
        },
        computed: {
            newUsers() {
                return this.oldClujistesWantRevalidation.concat(
                    this.lambdaUsers.filter(user => Array.isArray(user.associations) && user.associations.length)
                );
            },
            actionsNewUsers() {
                return [
                    {
                        key: 'action_accept',
                        color: () => 'success',
                        icon: 'check',
                        tooltip: "Valider l'inscription",
                        handler: ({item}) => this.confirmSignup(item, true)
                    },
                    {
                        key: 'action_refuse',
                        color: () => 'danger',
                        icon: 'times',
                        tooltip: "Refuser l'inscription",
                        handler: ({item}) => this.confirmSignup(item, false)
                    },
                    ...this.actions
                ];
            },
            fieldsAsso() {
                return [
                    ...this.fields,
                    {
                        key: 'associations',
                        label: 'Associations',
                        sortable: true,
                        formatter: value => value.map(({nom}) => nom).join(', ')
                    },
                    this.fieldActions
                ]
            },
            fieldsRoles() {
                return [
                    ...this.fields,
                    {
                        key: 'roles',
                        label: 'Statut',
                        sortable: true,
                        formatter: value => formatRole(value)
                    },
                    this.fieldActions
                ]
            },
            fieldsLambda() {
                return [
                    ...this.fields,
                    this.fieldActions
                ]
            }
        },
        methods: {
            loadData() {
                alert.loading();
                this.loadUsers('clujiUsers', 'ROLE_CLUJI')
                    .then(() => this.loadUsers('assoUsers', 'ROLE_ASSO'))
                    .then(() => this.loadUsers('lambdaUsers', 'ROLE_USER', 1))
                    .then(() => this.loadOldClujistesWantRevalidation())
                    .finally(alert.stopLoading);
            },
            loadOldClujistesWantRevalidation() {
                return this.axios
                    .get(http.apiPath('admin_old_clujistes_want_revalidation'))
                    .then(response => this.oldClujistesWantRevalidation = momentDates(response.data))
                    .catch(() => this.$toaster.error("Erreur : la liste des vieux clujistes n'a pas pu être récupérée"));
            },
            loadUsers(destination, role, exclusive = 0) {
                return this.axios
                    .get(http.apiPath('admin_user_list', {role: role, exclusive: exclusive}))
                    .then(response => this[destination] = momentDates(response.data))
                    .catch(() => this.$toaster.error("Erreur : la liste des utilisateurs n'a pas pu être récupérée"));
            },
            editUserModal(user) {
                this.$store.dispatch('modal/create', {
                    component: AdminEditUserModal,
                    props: {user: user, callback: () => this.loadData()}
                });
            },
            confirmSignup(user, accept) {
                this.$store.dispatch('modal/create', {
                    component: AdminUserValidate,
                    props: {user: user, valid: accept, callback: () => this.loadData()}
                });
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
