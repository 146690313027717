export const formatStrongestRole = roles => roles.includes('ROLE_WEBCRETAIRE') ? 'Webcrétaire'
    : roles.includes('ROLE_BUREAU') ? 'Bureau'
        : roles.includes('ROLE_CLUJI') ? 'Clujiste'
            : roles.includes('ROLE_ASSO') ? 'Association extérieure'
                : roles.includes('ROLE_USER') ? 'Utilisateur sans droits'
                    : 'Utilisateur non valide';

export const formatRole = roles => formatStrongestRole(roles) !== 'Association extérieure' && roles.includes('ROLE_ASSO')
    ? (formatStrongestRole(roles) + ' & Association extérieure')
    : formatStrongestRole(roles);

export default {formatStrongestRole, formatRole};